export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'background',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem 1rem'],
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },

        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem',
          },
        },
        '.popUp': {
          transform: 'unset',
        },
      },
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',

      '.logo': {
        img: {
          filter: 'unset',
          maxHeight: ['80px', '80px', '80px', '120px'],
        },
      },
      '.logoScrolled ': {
        img: {
          filter: 'unset',
          maxHeight: ['60px', '60px', '60px', '120px'],
          height: 'unset',
        },
      },
    },

    '.smallNavMenu': {
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          color: 'primary',
          fontFamily: 'body2',
          fontWeight: 'bold',
          ':hover': {
            textDecoration: 'underline',
          },
        },
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          color: 'primary',

          padding: '0.5rem',
          border: 'solid 1px',
          borderColor: 'primary',
        },
      },
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      border: 'none',
      height: '45px',
      div: {
        backgroundColor: 'primary',
      },
      'div:nth-of-type(2)': {
        width: '70%',
      },
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      color: 'white',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '100px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%'],
        },
      },
      '.navItem': {
        a: {
          fontSize: ['0.9rem', '1rem', '1.25rem', '1.25rem'],
        },
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right',
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'white',
          svg: {
            path: { fill: 'white' },
          },
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row'],
        },
      },
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
  },

  footer: {
    // borderTop: 'solid 2px',
    // borderColor: 'primary',
    // paddingBottom: ['7rem', '', '2rem'],
    // '.gonationLogo': {
    //   width: 'auto',
    // },
    // '.logo': {
    //   filter: 'brightness(0) invert(1)',
    // },
    // '.multiButtonContainer': {
    //   a: {
    //     variant: 'buttons.secondary',
    //   },
    // },
    '.ctaButton': {
      color: 'white',
      backgroundColor: 'primary',
    },
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3.5rem', '4.5rem', '5.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'white',
    order: '1',
  },
  subtitle: {
    color: '#dfb280',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.2rem', '1.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontFamily: 'display',
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',

      textAlign: 'left',
      fontSize: 'inherit',
    },
  },

  borderCorners: {
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1639683081/sites/the-bear-barber/corner.png)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '200px',
      height: '200px',
      position: 'absolute',
      left: '-1rem',
      top: '-1rem',
    },
    // '::after': {
    //   content: "''",
    //   backgroundImage:
    //     'url(https://res.cloudinary.com/gonation/image/upload/v1639683081/sites/the-bear-barber/corner.png)',
    //   backgroundSize: 'contain',
    //   backgroundRepeat: 'no-repeat',
    //   width: '200px',
    //   height: '200px',
    //   position: 'absolute',
    //   bottom: '-1rem',
    //   right: '-1rem',
    //   transform: 'rotate(180deg)',
    // },
  },

  imageFade: {
    // display: 'flex',
    // flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1639682816/sites/the-bear-barber/bordergrey.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: ['40px', '', '50px'],
      position: 'absolute',
      width: '100vh',
      bottom: '0rem',
      left: '-2px',
      transform: 'rotate(90deg) translateX(-100%)',
      transformOrigin: 'left bottom',
      filter: 'brightness(0)',
      zIndex: '99',
    },
    '::after': {
      content: "''",
      display: 'block',
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1639682816/sites/the-bear-barber/bordergrey.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: ['40px', '', '50px'],
      position: 'absolute',
      width: '100vh',
      bottom: '0rem',
      right: '-2px',
      transform: 'rotate(-90deg) translateX(100%)',
      transformOrigin: 'right bottom',
      filter: 'brightness(0)',
    },
  },
  imageFadeTopBottom: {
    position: 'relative',
    overflow: 'hidden',
    padding: '20vh 1rem',
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1639682816/sites/the-bear-barber/bordergrey.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: ['40px', '', '50px'],
      position: 'absolute',
      backgroundSize: '100%',
      width: '100vw',
      top: '-1px',
      left: '0rem',
      transform: 'rotate(180deg)',
      filter: 'brightness(0)',
    },
    '::after': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1639682816/sites/the-bear-barber/bordergrey.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: ['40px', '', '50px'],
      position: 'absolute',
      width: '100vw',
      backgroundSize: '100%',
      bottom: '-1px',
      left: '0rem',
      filter: 'brightness(0)',
    },
  },

  sideBySide1: {
    padding: ['2rem 1rem', '2rem', '2rem', '12vh 3rem', '15vh 4rem'],
    '.lazyload-wrapper': {
      maxHeight: '75vh',
      height: ['', '', '50vh', '75vh'],
      position: 'relative',
      variant: 'customVariants.imageFade',
      borderRadius: '10px',
    },
    '.content': {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      margin: ['1rem 0rem', '', '', '2rem', '3rem'],
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.imageContainer': {
      maxHeight: '75vh',
      height: ['', '', '50vh', '75vh'],
      position: 'relative',
      variant: 'customVariants.imageFade',
    },
    '.content': {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      margin: ['1rem 0rem', '', '', '2rem', '3rem'],
    },
    a: {
      order: '4',
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['0rem', '3rem'],
    margin: '0rem auto 25vh',
    width: 'calc(100% - 5vw)',

    '.hero_content_container': {
      marginLeft: ['1rem', '', '5rem'],
      bottom: ['2rem', '', '0rem'],
      top: 'unset',
      transform: 'unset',
    },

    '.section': {
      margin: 'auto auto',
      display: 'none',
      padding: '0.5rem',
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center',
      },
    },
    a: {
      order: '5',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      margin: '0rem 0rem',
    },
    '.slick-slider': {
      variant: 'customVariants.imageFade',
    },

    '.slick-dots': {
      width: 'fit-content',
      right: '1rem',
    },

    'svg.slick-arrow': {
      width: ['30px', '', '40px'],
      height: ['30px', '', '40px'],
      border: 'solid 2px',
      borderColor: 'primary',
      padding: '0.25rem',
      borderRadius: '100px',
      backgroundColor: '#f6f6f6',
      color: 'black',
      top: 'calc(140px - 1rem)',
      bottom: 'unset',
      left: 'unset',
    },
    'svg.slick-prev': {
      right: ['3rem', '', '4rem'],
    },
    'svg.slick-next': {
      right: ['0.5rem', '', '1rem'],
    },
    // '.slick-slide': {
    //   '> div': {
    //     variant: 'customVariants.imageFade',
    //   },
    // },
  },

  homepageHeroShout: {
    display: 'none !important',
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      backgroundColor: 'secondary',
      fontFamily: 'body',
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', '#5f4739'],
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem',
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4'],
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
  },

  homepageProducts: {
    variant: 'customVariants.imageFadeTopBottom',
    margin: '10vh 0rem',
    textAlign: 'center',
    '.section': {
      backgroundColor: '#000000cc',
      height: 'fit-content',
      margin: 'auto',
      padding: '2rem',
      border: 'solid 3px #b4a992',
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      p: {
        textAlign: 'center',
      },
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageQuote1: {
    padding: '20vh 1rem',
    flexDirection: ['column', 'column', 'column', 'column'],
    alignItems: ['center', 'center', 'center', 'center'],
    justifyContent: ['center', 'center', 'center', 'center'],
    '.lazyload-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        maxWidth: '250px',
        objectFit: 'contain',
      },
    },
    '.content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      order: '2',
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::after': {
        content: "''",
        height: '80px',
        width: '2px',
        backgroundColor: 'primary',
        margin: '1rem',
      },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::after': {
        content: "''",
        height: '80px',
        width: '2px',
        backgroundColor: 'primary',
        margin: '2rem 0rem 1rem',
      },
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'white',
      marginBottom: '4rem',
      p: {
        textAlign: 'center',
        fontFamily: 'body2',
        fontWeight: 'bold',
        color: 'white',
        fontSize: '1.7rem',
        lineHeight: '1.25',
      },
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageContactUs: {
    '.hero_content_container': {
      variant: 'customVariants.borderCorners',
      backgroundColor: '#1d2020de',
      marginLeft: ['2rem', '', '', '2rem'],
      width: 'calc(100% - 4rem)',
    },
    '.title': {
      variant: 'customVariants.title',
      order: '1',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageReservations: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    flexDirection: 'column',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center',
    },
    '.title': {
      variant: 'customVariants.title',
      order: '1',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1',
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1',
  },
  venueBar: {
    variant: 'customVariants.sideBySide1',
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem',
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2',
      },
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset',
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
      },
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%',
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary',
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem',
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '2rem', '2rem', '2rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center',
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem',
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
      },
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
    },
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black',
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem',
      },
    },
  },

  pressPage: {
    backgroundColor: 'background',
  },
}
