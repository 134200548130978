export default {
  heading: 'Burford-Rustic, sans-serif',
  body: 'EB Garamond, serif',
  body2: 'Syncopate, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cinzel, serif',
  googleFonts: [
    'Cinzel:100,200,300,400,500,600,700,800,900',
    'Hahmlet:100,200,300,400,500,600,700,800,900',
    'EB Garamond:400,500,600,700,800',
    'Rye',
    'Syncopate:400,700',
  ],
  customFamilies: ['Burford-Rustic'],
  customUrls: [
    'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  ],
}
